<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="daYinJiBH" label="打印机编号" />
            <x-detail-item prop="daYinJiMC" label="打印机名称" />
            <x-detail-item prop="daYinJiKey" label="打印机Key" />
            <x-detail-item prop="fenYeDaYin" label="分页打印" />
            <x-detail-item prop="biaoQianDaYin" label="标签打印" />
            <x-detail-item prop="xiaoPiaoTouBuWenZi" label="小票头部文字" />
            <x-detail-item prop="xiaoPiaoDiBuWenZi" label="小票底部文字" />
            <x-detail-item prop="zhuangTai" label="状态" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/sbgl/DaYinJi";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                daYinJiBH: "", // 打印机编号
                daYinJiMC: "", // 打印机名称
                daYinJiKey: "", // 打印机Key
                fenYeDaYin: "", // 分页打印
                biaoQianDaYin: "", // 标签打印
                xiaoPiaoTouBuWenZi: "", // 小票头部文字
                xiaoPiaoDiBuWenZi: "", // 小票底部文字
                zhuangTai: "", // 状态
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>