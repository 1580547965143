<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="700px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="打印机编号" prop="daYinJiBH" class="line">
                <el-input v-model="form.daYinJiBH" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="打印机名称" prop="daYinJiMC" class="line">
                <el-input v-model="form.daYinJiMC" size="small" maxlength="50" show-word-limit/>
            </el-form-item>
            <el-form-item label="打印机Key" prop="daYinJiKey" class="line">
                <el-input v-model="form.daYinJiKey" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="分页打印" prop="fenYeDaYin" class="line">
                <x-switch v-model="form.fenYeDaYin" size="small" width="50%" dictType="T_USE"/>
                <span class="span-class">分页及标签打印时,订单仍统计总额</span>
            </el-form-item>
            <el-form-item v-if="form.fenYeDaYin===useDictTrue" label="每页条数" prop="meiYeTiaoShu" class="line">
                <el-input v-model="form.meiYeTiaoShu" size="small" maxlength="50" show-word-limit/>
            </el-form-item>
            <el-form-item label="标签打印" prop="biaoQianDaYin" class="line">
                <x-switch v-model="form.biaoQianDaYin" width="50%" size="small" dictType="T_USE"/>
                <span class="span-class">如果只有一台打印机,为了节省资源,建议整单打印</span>
            </el-form-item>
            <el-form-item v-if="form.biaoQianDaYin===useDictTrue" label="标签内容" prop="biaoQianNeiRong" class="line">
                <el-input v-model="form.biaoQianNeiRong" size="small" maxlength="50" show-word-limit/>
            </el-form-item>
            <el-form-item label="小票头部文字" prop="xiaoPiaoTouBuWenZi" class="line">
                <el-input v-model="form.xiaoPiaoTouBuWenZi" size="small" maxlength="50" show-word-limit/>
            </el-form-item>
            <el-form-item label="小票底部文字" prop="xiaoPiaoDiBuWenZi" class="line">
                <el-input v-model="form.xiaoPiaoDiBuWenZi" size="small" maxlength="50" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/sbgl/DaYinJi";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_USE} from '@/util/constant'

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required,digits} = ruleBuilder;
            this.rules = {
                daYinJiBH: [required('string', ['blur', 'change'])], // 打印机编号
                daYinJiMC: [required('string', ['blur', 'change'])], // 打印机名称
                daYinJiKey: [required('string', ['blur', 'change'])], // 打印机Key
                fenYeDaYin: [], // 分页打印
                meiYeTiaoShu:[digits(),required('string', ['blur', 'change'])],// 每页条数
                biaoQianDaYin: [], // 标签打印
                biaoQianNeiRong:[required('string', ['blur', 'change'])],// 标签内容
                xiaoPiaoTouBuWenZi: [required('string', ['blur', 'change'])], // 小票头部文字
                xiaoPiaoDiBuWenZi: [required('string', ['blur', 'change'])], // 小票底部文字
                zhuangTai: [], // 状态
            }
            this.titlePrefix = '打印机';
            this.defaultForm = {
                id: null,
                daYinJiBH: "", // 打印机编号
                daYinJiMC: "", // 打印机名称
                daYinJiKey: "", // 打印机Key
                fenYeDaYin: "false", // 分页打印
                meiYeTiaoShu:'',
                biaoQianDaYin: "false", // 标签打印
                biaoQianNeiRong:'',
                xiaoPiaoTouBuWenZi: "", // 小票头部文字
                xiaoPiaoDiBuWenZi: "", // 小票底部文字
                zhuangTai: "true", // 状态
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data (){
            return {
                useDictTrue:DICT_USE.ENABLE.key
            }
        }
    }
</script>

<style scoped>
    .span-class {
        color: #009688;
        font-weight: bold;
        font-size: 12px;
        margin-left: 10px;
    }
</style>
